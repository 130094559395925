body,
html {
    margin: 0;
    padding: 0;
    height: 100% !important;
    /* display: flex; */
    flex-direction: column;
    font-family: Open Sans !important;
    overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
    font-family: Open Sans !important;
    /* or any desired font */
    font-size: medium !important;
    /* or any desired size */
    line-height: normal !important;
    /* Add any other custom styles */
}




  /* For Webkit-based browsers */
  ::-webkit-scrollbar {
    width: 5px;
    /* Width of the scroll bar */
    border-radius: 5px;
    /* Curved corners */
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Background color of the track */
    border-radius: 5px;
    /* Curved corners */
}

::-webkit-scrollbar-thumb {
    background: #888;
    /* Color of the scroll thumb */
    border-radius: 15px;
    /* Curved corners */
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Color of the scroll thumb on hover */
    border-radius: 15px;
    /* Curved corners */
}

.containerMenu {
    display: flex;
    width: 100%;
    height: 100%;
}




.isLoaderActivated {
  box-shadow: 0px 0px 80px -15px rgba(116, 203, 72, 0.73) inset;
  animation: glowAnimation 2s infinite ease-in-out;
}

@keyframes glowAnimation {
  0% {
    box-shadow: 0px 0px 20px -10px rgba(116, 203, 72, 0.5) inset;
  }
  20% {
    box-shadow: 0px 0px 40px -5px rgba(116, 203, 72, 0.7) inset;
  }
  50% {
    box-shadow: 0px 0px 60px 0px rgba(116, 203, 72, 0.9) inset;
  }
  70% {
    box-shadow: 0px 0px 30px -5px rgba(116, 203, 72, 0.4) inset;
  }
  100% {
    box-shadow: 0px 0px 20px -10px rgba(116, 203, 72, 0.5) inset;
  }
}
  


  pre {
    display: block !important;
    padding: 9.5px !important;
    margin: 0 0 10px !important;
    font-size: 13px !important;
    line-height: 1.42857143 !important;
    color: white !important;
    /* word-break: break-all !important; */
    /* word-wrap: break-word !important; */
    background-color: black !important;
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
}

/* Ensure specific rule for <pre> tags with language classes */
pre[class*="language-"] {
  overflow: visible !important; /* Prevent horizontal scrolling on <pre> */
}
/* Apply horizontal scrolling to <code> tags inside <pre> */
pre[class*="language-"] code {
  display: block !important; /* Ensure block-level display */
  max-width: 100% !important; /* Limit width to the container */
  overflow-x: auto !important; /* Enable horizontal scrolling */
  white-space: nowrap !important; /* Prevent line breaks */
  padding: 0 10px !important; /* Optional padding */
}

pre {
  position: relative; /* Makes it the containing block */
  padding: 8px; /* Adds padding for readability */

  border: 1px solid #ddd; /* Border for visual distinction */
  overflow: hidden; /* Prevent content from spilling out */
  max-width: 100%; /* Ensure the pre container adapts to its parent */
}

/* pre code {
  display: block; 
  max-width: 100%; 
  overflow-x: auto;
  white-space: pre; 
  padding: 4px;
  width: 700px !important;
  scrollbar-width: thin !important; 
  scrollbar-color: #888 #f1f1f1 !important;
} */


/* Optionally, you can apply a max-width to the markdown container itself to constrain it */
/* Ensure markdown tag doesn't affect the width of code */
markdown {
  display: block;
  max-width: 100% !important;
  width: 100% !important;
  overflow-x: auto;
}

/* Target the horizontal scrollbar */
::-webkit-scrollbar {
  height: 8px; /* Adjust the height of the scrollbar */
}

/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Optional: set the background */
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: #888; /* Optional: set the scrollbar color */
  border-radius: 4px; /* Optional: add some rounding */
}

/* Style on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Optional: darker color on hover */
}


pre code {
  display: block;
  max-width: 100%;
  width: 100%; 
  overflow-x: auto; 
  white-space: pre; 
  padding: 4px; 
  max-width: 100%;
  max-width: 670px;
  scrollbar-width: thin !important; 
  scrollbar-color: #888 #f1f1f1 !important; 
}

@media (max-width: 768px) {
  pre code {
    width: 100%; /* Takes full width on smaller screens */
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  pre code {
    padding: 2px; /* Adjust padding for smaller screens */
  }
}


/* For WebKit-based browsers (Chrome, Edge, Safari) */
pre code::-webkit-scrollbar {
  height: 8px; /* Height of the scrollbar */
}

pre code::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded edges for the scrollbar thumb */
}

pre code::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb when hovered */
}

pre code::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the scrollbar track */
  border-radius: 4px; /* Rounded edges for the scrollbar track */
}


.preTagHeader {
  border-bottom: outset;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  margin: -5px !important;
}

.clsCopy {
  text-align: right;
  color: #3f3c3c;
  cursor: pointer;
}

.clsLanguage {
  text-align: right;
}

.preTagHeader {
  margin-bottom: 10px !important;
  /* Adjust the value as needed */
  margin-top: -6px !important;
}

pre code {
  display: block;
  margin-top: 20px !important;
  /* Adjust the value as needed */
}

.fa-clone{
  font-size: 13px !important;
  color: white;
  padding: 3px !important;
  border-radius: 13px !important;
  margin-top: 0px !important;
  background: none !important;
}

::ng-deep markdown table {
  border-collapse: collapse !important;
  border: 1px solid #ddd !important;
}

::ng-deep markdown table th,
::ng-deep markdown table td {
  border: 1px solid #0b0a0a !important;
  padding: 8px !important;
  text-align: left !important;
}

::ng-deep markdown table th {
  background-color: #f8f8f8 !important;
}

markdown table {
  border-collapse: collapse !important;
  border: 1px solid #ddd !important;
}

 markdown table th,
 markdown table td {
  border: 1px solid #0b0a0a !important;
  padding: 8px !important;
  text-align: left !important;
}

 markdown table th {
  background-color: #f8f8f8 !important;
}


/* Apply to pre and code elements within botmessage */
.botmessage pre {
  max-width: 100%; /* Ensure the pre tag respects the width of botmessage */
  width: 100%; /* Make sure the code block doesn't extend beyond its container */
  overflow-x: auto; /* Allow horizontal scroll when content overflows */
  white-space: pre; /* Preserve formatting inside the code block */
  padding: 4px; /* Padding for better visibility */
}

.botmessage pre code {
  display: block;
  /* width: 100%; Inherit the width of the pre element */
  /* white-space: pre-wrap; Ensure long lines wrap within the container */
  /* word-wrap: break-word; Break the long words if needed */
  overflow-x: auto; /* Enable scrolling if content is too wide */
  max-width: 1000px;
}

/* Scrollbar customization (optional) */
.botmessage pre code {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f1f1f1; /* Thumb and track color */
}

textarea{
  resize:none !important;
}

.systemsettings .dds-modal {
  padding: 0px !important;
  border-radius: 10px !important;
  align-self: center !important;
}

.systemsettings .dds-modal__body{
  padding-left: 14px !important;
  padding-right:14px !important;
}

.systemsettings .dds-modal__header{
  padding-left: 14px !important;
  padding-right:14px !important;
  padding-top:28px !important;
  padding-bottom: 0px !important;
}

.systemsettings .dds-modal__footer{
  padding-left: 14px !important;
  padding-right:14px !important;
  padding-top:5px !important;
  padding-bottom: 28px !important;
}

.systemsettings .dds-textarea__field  {
  border-radius: 4px !important;
  padding :10px !important;
  border-style:none !important;
  min-height: 8.4rem !important;
  border: 1px solid #e2e2e2 !important;
}

.systemsettings .dds-select {
  width: 40% !important;
  
}

.systemsettings .dds-modal_lg {
  width: 700px !important;
}

.systemsettings .dds-modal__body{
  min-height: unset !important;
  size: 5px !important;
}

.systemsettings .applybutton .dds-btn{
  background-color: #86BC25 !important ;
  box-shadow: none;
  justify-content: center !important;
}

.dds-btn__content{
  justify-content: center !important;
}

.systemsettings .applybutton .dds-btn:hover{
  background-color: #86BC25 !important ;
  box-shadow: none;
}

.systemsettings .dds-btn__text {
  overflow: unset !important;
  font-size: 13px !important;
  margin-right: -10px;
} 

.systemsettings .dds-select__field {
  background-color: #f9f9f9 !important;
  box-shadow :none !important;
  border: 1px solid #e2e2e2 !important;
}


span.dds-select__description{
  margin-left: 0px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  font-weight: 300 !important;
}
.systemsettings .dds-textarea_with-label .dds-textarea__field
{
  background-color: #f9f9f9 !important;
  width: 691px;
  height: 100px !important;
  top: 89px;
  left: 22px;
  gap: 0px;
  border-radius: 22px 0px 0px 0px;
  opacity: 0px;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px  !important;
  letter-spacing: -0.011em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.systemsettings .dds-textarea__label {
  color:black!important;
  font-size:17px !important;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.011em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-top: 0px;
  margin-left: 10px;
  margin-bottom: 7px;
}

.systemsettings .dds-select__title{
  color: black  !important;
  font-size: 17px !important;
  /* padding-left: 3px !important; */
  padding-bottom: 5px !important;
  font-weight: 500 !important;

  height: 21px !important;
  gap: 0px;
  opacity: 0px;

}
.systemsettings .dds-select, .dds-select * {
  /* margin-left: 3px; */
  border-radius: 4px !important;
  font-size:15px !important;
  font-weight:700 !important;
}

.systemsettings .dds-modal__title {
  font-size: 25px ;
}
.systemsettings .dds-context-menu, dds-context-menu * {
  /* background-color: #f9f9f9 !important; */
  background-color: #f9f9f9 !important;
  border-radius: 4px !important;
  border-style:none !important;
  font-size:15px !important;
  font-weight:bold !important;
}


hr.dividertop { 
  margin-top: 0em;
  border-width: 2px;
} 

hr.dividerbottom{
  border-width:2px;
}

.btn-Apply button{
border-radius: 4px !important;
}

.btn-reset button{
  border-radius: 4px !important;
  background: #ccc !important;
  width: auto !important;
  }
  
  .system-prompt .dds-textarea__header{
    margin-left: -10px !important;
  }

/* .txtsystemprompt .dds-textarea__wrap{
  border: 1px solid #e2e2e2 !important;
  border-radius: 10px;
} */

.dds-tooltip_lg{
  /* width: 374px !important; */
  /* height: 196px !important; */
  padding: 12px 16px 12px 16px;
  gap: 10px;
  border-radius: 6px 6px 6px 6px;
  background-color: #525252;

  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;

}
.middleModelPopup{
  left: calc(50% - 215px) !important;
}

.middlefeedBacklikeModelPopup{
  left: calc(50% - 100px) !important;
}
.middlefeedBackdislikeModelPopup{
  left: calc(50% - 330px) !important;
}
.middleWhatsNewModelPopup{
  left: calc(50% - 100px) !important;
}

.middlePreviewModelPopup{
  left: calc(50% - 160px) !important;
}

.middleEFSPreviewFileModelPopup{
  left: calc(50% + 160px) !important;
}


.icontick{
  font-size: 15px !important;
  color: white !important;
  background: none !important;
  padding: 0px !important;
  border-radius: 0px !important;
  margin-top: 0px !important;
}

/* [ngClass]="{'middlefeedBackModelPopup': appcomponentService.isPinChecked}" */

/* Styles for category dropdown - Agnet Central */

.category-filter-custom button{
  background: #BBBCBC26;
  border: 1px solid #BBBCBC;
  height: 27px;
  border-radius: 6px !important;
} 

 .category-filter-custom .dds-select__placeholder {
  /* font-size: 10px !important;
  font-weight: 700 !important; 
  line-height: 16px; */
  font-family: Open Sans !important;
  color: #000000;
}

/* .category-filter-custom .dds-select__active-item {
  font-size: 10px !important;
  font-weight: 700 !important; 
} */

.docx-wrapper {
  background: white !important;
  padding: 30px;
  padding-bottom: 0px;
  display: flex
;
  flex-flow: column;
  align-items: center;
  padding: 10px !important;
}

section.docx {
  box-sizing: border-box;
  display: flex
;
  flex-flow: column nowrap;
  position: relative;
  overflow: hidden;
  padding: 1pt 90pt !important;
  width: 750px !important;
}

element.style {
  padding: 10px 10px !important;
  /* width: 612pt; */
  /* min-height: 792pt; */
}

h5{
  word-wrap: break-word !important;
  white-space: normal !important;
  overflow-wrap: break-word !important;
}

.chatsettings .dds-modal_lg {
  width: 800px !important;
}

.chatsettings .dds-modal__body{
  min-height: unset !important;
  size: 5px !important;
}
